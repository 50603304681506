import cn from 'classnames';
import { useState } from 'react';
import { useSuspenseFeatureFlagsQuery } from '#api/org.queries';
import { Hamburger } from '#components/atoms/buttons/hamburger/Hamburger';
import { Link } from '#components/atoms/link/Link';
import { Logo } from '#components/atoms/logo/Logo';
import { Nav } from '#components/atoms/nav/Nav';
import { NavGroupItem } from '#components/atoms/nav/nav-group-item/NavGroupItem';
import { NavGroup } from '#components/atoms/nav/nav-group/NavGroup';
import { NavItem } from '#components/atoms/nav/nav-item/NavItem';
import { SkipLink } from '#components/atoms/skip-link/SkipLink';
import { useAppLocation } from '#hooks/useAppLocation';
import { UserMenu } from '../../sidebar/user-menu/UserMenu';
import styles from './NavLayout.module.css';

type Props = {
  children: React.ReactNode;
};

export function NavLayout({ children }: Props) {
  const { pathname } = useAppLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  // We only want one of settings or support to be open at a time,
  // and it could be different from the active one, so track separately
  // IMPORTANT: If adding more NavGroups, update these types, as well as the `setActiveSubnav(null)` guards
  const [openSubnav, setOpenSubnav] = useState<'settings' | 'support' | null>(null);
  const [activeSubnav, setActiveSubnav] = useState<'settings' | 'support' | null>(null);

  // Get feature flags
  const { data: featureFlags } = useSuspenseFeatureFlagsQuery();

  const { LIST_CERT_AUTHORITIES } = featureFlags;

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className={cn(styles.NavLayout, { [styles.NavLayout___sidebarVisible]: isMenuOpen })}>
      <div className={styles.NavLayout_header}>
        <SkipLink href="#page-main">Go to main content</SkipLink>
        <Link to="/">
          <Logo height={32} />
        </Link>
        <div className={styles.NavLayout_hamburger}>
          <Hamburger
            isOpen={isMenuOpen}
            onClick={() => {
              setMenuOpen(!isMenuOpen);
            }}
          />
        </div>
      </div>
      <div className={styles.NavLayout_sidebar}>
        <Nav>
          <NavItem to="/hosts" onClick={closeMenu}>
            Hosts
          </NavItem>
          <NavItem to="/lighthouses" onClick={closeMenu}>
            Lighthouses
          </NavItem>
          <NavItem to="/relays" onClick={closeMenu}>
            Relays
          </NavItem>
          <NavItem to="/routes" onClick={closeMenu}>
            Routes
          </NavItem>
          <NavItem to="/roles" onClick={closeMenu}>
            Roles
          </NavItem>

          {LIST_CERT_AUTHORITIES ?
            <NavItem to="/certificate-authorities" onClick={closeMenu}>
              Certificate Authorities
            </NavItem>
          : null}

          <NavItem to="/tags" onClick={closeMenu}>
            Tags
          </NavItem>
          <NavItem to="/logs/audit" onClick={closeMenu}>
            Logs
          </NavItem>
          <NavGroup
            title="Settings"
            isOpen={openSubnav === 'settings'}
            isActive={activeSubnav === 'settings'}
            setOpen={(open) => {
              setOpenSubnav(open ? 'settings' : null);
            }}
            setActive={(active) => {
              if (active) {
                setActiveSubnav('settings');
              } else if (activeSubnav === 'settings') {
                // HACK: We don't want to unset the active subnav if it just moved to a support link
                if (!pathname.startsWith('/downloads')) {
                  setActiveSubnav(null);
                }
              }
            }}
          >
            <NavGroupItem to="/settings/billing" onClick={closeMenu}>
              Billing
            </NavGroupItem>
            <NavGroupItem to="/settings/networks" onClick={closeMenu}>
              Networks
            </NavGroupItem>
            <NavGroupItem to="/settings/api-keys" onClick={closeMenu}>
              API keys
            </NavGroupItem>

            <NavGroupItem to="/settings/sso" onClick={closeMenu}>
              Single sign-on
            </NavGroupItem>
          </NavGroup>
          <NavGroup
            title="Support"
            isOpen={openSubnav === 'support'}
            isActive={activeSubnav === 'support'}
            setOpen={(open) => {
              setOpenSubnav(open ? 'support' : null);
            }}
            setActive={(active) => {
              if (active) {
                setActiveSubnav('support');
              } else if (activeSubnav === 'support') {
                // HACK: We don't want to unset the active subnav if it just moved to settings
                if (!pathname.startsWith('/settings')) {
                  setActiveSubnav(null);
                }
              }
            }}
          >
            <NavGroupItem to="https://docs.defined.net" external={true}>
              Documentation
            </NavGroupItem>
            <NavGroupItem to="/support/contact">Contact us</NavGroupItem>
            <NavGroupItem to="/downloads" onClick={closeMenu}>
              Downloads
            </NavGroupItem>
          </NavGroup>
        </Nav>
        <UserMenu />
      </div>
      <main id="page-main" className={styles.NavLayout_main}>
        {children}
      </main>
    </div>
  );
}
